.table {
  width: 100%;
}
.table tbody {
  width: 100%;
}
.table tr,
.table td {
  border: none;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.45rem 0;
}
