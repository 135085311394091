.btn-default {
  border: none;
  background: none;
  background-image: linear-gradient(to right, #9833d4 0%, #354e8f 51%, #9833d4 100%);
  background-size: 200% auto;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 0;
  height: 50px;
  padding: 0.5rem 2rem;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
}
.btn-default:hover {
  background-position: right center;
}
.btn-gradient {
  background-image: linear-gradient(90.83deg, #9833d4 0.36%, #354e8f 99.77%);
  /* border-radius: 20px; */
  background-size: 200% auto;
  height: 50px;
  width: auto;
  color: #fff;
  border: none;
  font-size: 1.4rem;
  padding: 0.5rem 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}
.btn-large {
  border: none;
  background: none;
  background-image: linear-gradient(to right, #48bcd0 0%, #354e8f 51%, #9833d4 100%);
  background-size: 200% auto;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  border-radius: 0;
  height: 50px;
  padding: 0.5rem 5rem;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.5s ease-in-out;
}

.btn-gradient:hover,
.App-button-two:hover,
.btn-large:hover {
  /* background: linear-gradient(90.83deg, #354e8f 99.77%, #48bcd0 0.36%); */
  background-position: 40% center;
}
.Claim-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4rem;
}

.App-claim {
  font-size: 2.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.App-button-two {
  background-image: linear-gradient(90.83deg, #d4337f 0.36%, #6a1bc2 99.77%);
  background-size: 200% auto;
  /* border-radius: 20px; */
  height: 5rem;
  width: auto;
  color: #fff;
  border: none;
  font-size: 1.4rem;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}
.btn-getAlex img {
  width: 100%;
}
.btn-getAlex {
  max-width: 315px;
  padding: 30px 50px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-getAlex.t-opensea {
  background-color: #1868b7;
}
.btn-getAlex.t-looksrare {
  background-color: #000000;
}
