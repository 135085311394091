@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;0,900;1,600;1,900&display=swap);
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:root {
  --bs-body-bg: none !important;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  background-image: url(/static/media/background-4.e54305cc.png);
  background-color: #040a1a;
}

.App {
  /* text-align: center; */
  margin: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* row-gap: 4rem; */
  border-top: 10px solid #48bcd0;
  border-bottom: 10px solid #48bcd0;
  /* border-bottom: 10px solid #48bcd0; */
  min-height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 2rem;
  padding-right: 2rem;
}

.App-logo {
  height: 8rem;
  width: 8rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
#gallery {
  max-width: 1000px;
  width: 100%;
}
.App-carousel {
  background-color: rgb(177, 177, 177);
  height: 45rem;
  width: 45rem;
}

@media only screen and (max-width: 1020px) {
  /* .App {
    align-items: inherit!important;
  } */
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.arrows {
  width: 5rem;
  height: 5rem;
}

.leftArrow {
  left: 1rem;
  /* margin-right: 2rem; */
}

.rightArrow {
  right: 1rem;
}

.arrow-box {
  text-align: center;
  margin-top: 2rem;
}

.arrow-box .leftArrow {
  margin-right: 1rem;
}

.arrow-box .rightArrow {
  margin-left: 1rem;
}


/* #links button.social {
  padding: 8px 15px;
  border-image-source: linear-gradient(to left, #354E8F, #48BCD0);
}

#links button.social:hover {
  background: linear-gradient(90.83deg, #48BCD0 0.36%, #354E8F 99.77%);
} */

.profile-image {
  height: 10rem;
  width: 10rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.user-profile p {
  font-size: 1.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}

.profile p {
  margin-bottom: 0rem;
}

.nft-container {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 30px;
  /* background-color: #e3e3e3; */
  background-color: #081433;
  border: 5px solid #48bcd0;
  /* border-bottom: 5px solid #41C2D2; */
  max-width: 1000px;
  width: 1000px;
  padding: 40px;
  margin-bottom: 4rem;
  /* margin: 0px 20px 60px 20px; */
}

.left-column img.image {
  width: 100%;
}

.tile {
  /* background-color: #fff; */
  background-color: #081333;
  margin-bottom: 25px;
  padding: 0px 30px;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
}

.tile.props {
  margin-bottom: 0px;
}

.tile h1 {
  font-size: 1.5rem;
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #48bcd0;
}

.tile.details h1{
  margin-bottom: 15px;
}

.tile p {
  font-size: 1.2rem;
  /* color: #707070; */
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

/* .tile.description p {
} */

.tile.details p {
  padding: 5px 0px;
}

.tile .properties p {
  padding: 5px 0px;
}

.information {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.tile .properties {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  text-align: center;
}

.property {
  /* background-color: #eef9fd; */
  background-color: #1e2a4b;
  border: 2px solid #73ceed;
  min-height: 80px;
  /* border-radius: 10px; */
  padding: 5px;
}

.property p.trait {
  text-transform: uppercase;
  color: #73ceed;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1rem;
}

.property p.type {
  color: #fff;
  font-size: 1.1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.property p.rarity {
  font-weight: bold;
}


@media only screen and (max-width: 1010px) {

  .profile {
      width: 100%;
  }

}

@media only screen and (max-width: 900px) {
  .nft-container {
    grid-template-columns: 1fr;
    /* width: 100%!important; */
    padding: 2rem;
  }


  .tile {
    padding: 0px 0px;
  }

  .tile.details .mobile-hide {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .tile .properties {
    grid-template-columns: 1fr 1fr;
  }

  .property {
    min-height: 0px;
  }
}


.table {
  width: 100%;
}
.table tbody {
  width: 100%;
}
.table tr,
.table td {
  border: none;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.45rem 0;
}


.launcher-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 4rem;
  row-gap: 4rem;
  padding-top: 2rem;
}

.launcherButton {
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  height: 5rem;
  width: auto;
  color: #fff;
  border: none;
  font-size: 1.4rem;
  padding-left: 30px;
  padding-right: 30px;
   font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.launcherButton:hover {
  /* background: linear-gradient(90.83deg, #354e8f 99.77%, #48bcd0 0.36%); */
  background: #48bcd0;
}

#navbar {
  display: grid;
  min-height: 100px;
  grid-auto-flow: column;
  background-color: none;
  grid-gap: 1.5em;
  padding: 0 0px;
  width: 100%;
  /* min-width: 1000px; */
  /* background-color: red; */
  /* margin-bottom: 4rem; */
}
nav {
  width: 100%;
  max-width: 1000px;
}

#logo {
  display: grid;
  justify-content: start;
  align-content: center;
}

.logo img {
  width: 25rem;
}

.logo.mobile {
  display: none;
}

.logo.desktop {
  display: block;
}

/* .opensea {
  height: 30px;
  width: 32px;
} */

#links {
  display: grid;
  justify-content: right;
  align-content: center;
  grid-auto-flow: column;
  grid-gap: 1.5em;
  font-family: "Open Sans", sans-serif;
}
/* 
#links a {

} */

#links button {
  min-width: 50px;
  min-height: 52px;
}

#links button.social {
  background-color: transparent;
  padding: 8px 15px;
  /* border-image-source: linear-gradient(to left, #354E8F, #48BCD0); */
  border: solid 3px #48bcd0;
  transition: all 0.3s ease-in-out;
  position: relative;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

#links button.social:hover {
  /* background: linear-gradient(90.83deg, #48BCD0 0.36%, #354E8F 99.77%); */
  background-color: #48bcd0 !important;
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
}

#links button.claim-nft {
  border: none !important;
  background: #48bcd0;
  color: #fff;
  padding: 8px 40px;
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

#links img {
  vertical-align: middle;
}

a:hover {
  color: #0474bc;
}

@media only screen and (max-width: 1020px) {
  #navbar {
    /* min-width: 100vw; */
    width: 100%;
    /* padding-left: 2rem;
    padding-right: 2rem; */
    /* align-items: inherit!important; */
    /* grid-template-columns: 1fr; */
  }

  #links a {
    display: none;
  }

  #logo {
    text-align: left;
  }

  .logo.desktop {
    display: none;
  }

  .logo.mobile {
    display: block;
  }

  #navbar .logo img {
    width: 5rem;
  }
}

@media only screen and (max-width: 650px) {
  #navbar {
  }

  #footer .logo img {
    width: 20rem;
  }

  #links button.social {
    display: none;
  }

  #links button {
    /* width: auto; */
    min-height: 25px;
  }

  #links button .name {
    min-height: 50px;
  }

  .App-button-one {
    font-size: 1rem !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .App-button-two {
    font-size: 1rem !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .App-button-three {
    font-size: 1rem !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (max-width: 600px) {
  /* #footer .logo img {
    width: 15rem;
} */
}

.Connect-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 4rem;
  row-gap: 4rem;
}

.App-connect {
  font-size: 2.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.App-button-one {
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  /* border-radius: 20px; */
  height: 5rem;
  width: auto;
  color: #fff;
  border: none;
  font-size: 1.4rem;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.App-button-one:hover {
  /* background: linear-gradient(90.83deg, #354e8f 99.77%, #48bcd0 0.36%); */
  background: #48bcd0;
}

.Claim-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 4rem;
  row-gap: 4rem;
}

.App-claim {
  font-size: 2.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

/* .dropdown {
    position: relative;
} */

.wallet-button {
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  border-radius: 0px;
  background-size: 150% auto;
  height: 5rem;
  width: auto;
  color: #fff;
  border: none;
  font-size: 1.4rem;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}
.wallet-button:hover {
  background-position: 80% center;
}

.dropdown-menu {
  min-width: 19rem;
  margin-top: 1rem;
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  border: none;
  padding: 0.4rem;
}

.dropdown-item {
  background-color: #081433;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #fff;
  position: relative;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}

.dropdown-item:hover {
  background-color: #1d2b4f;
  color: #fff;
}

.dropdown-item .name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.dropdown-item img {
  width: 3rem;
}

.btn-secondary:focus {
  box-shadow: none !important;
}

.btn-default {
  border: none;
  background: none;
  background-image: linear-gradient(to right, #9833d4 0%, #354e8f 51%, #9833d4 100%);
  background-size: 200% auto;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 0;
  height: 50px;
  padding: 0.5rem 2rem;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
}
.btn-default:hover {
  background-position: right center;
}
.btn-gradient {
  background-image: linear-gradient(90.83deg, #9833d4 0.36%, #354e8f 99.77%);
  /* border-radius: 20px; */
  background-size: 200% auto;
  height: 50px;
  width: auto;
  color: #fff;
  border: none;
  font-size: 1.4rem;
  padding: 0.5rem 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}
.btn-large {
  border: none;
  background: none;
  background-image: linear-gradient(to right, #48bcd0 0%, #354e8f 51%, #9833d4 100%);
  background-size: 200% auto;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  border-radius: 0;
  height: 50px;
  padding: 0.5rem 5rem;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.5s ease-in-out;
}

.btn-gradient:hover,
.App-button-two:hover,
.btn-large:hover {
  /* background: linear-gradient(90.83deg, #354e8f 99.77%, #48bcd0 0.36%); */
  background-position: 40% center;
}
.Claim-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 4rem;
  row-gap: 4rem;
}

.App-claim {
  font-size: 2.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.App-button-two {
  background-image: linear-gradient(90.83deg, #d4337f 0.36%, #6a1bc2 99.77%);
  background-size: 200% auto;
  /* border-radius: 20px; */
  height: 5rem;
  width: auto;
  color: #fff;
  border: none;
  font-size: 1.4rem;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}
.btn-getAlex img {
  width: 100%;
}
.btn-getAlex {
  max-width: 315px;
  padding: 30px 50px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-getAlex.t-opensea {
  background-color: #1868b7;
}
.btn-getAlex.t-looksrare {
  background-color: #000000;
}

#pop-up-warning {
  position: fixed;
  bottom: 2rem;
  right: -100%;
  padding: 2rem;
  transition: right 0.5s ease-in-out;
  background: #040a1a;
  border: 2px solid #48bcd0;
  color: #41c2d2;
  font-size: 1.4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  box-shadow: 0 0 7px #fff;
}
#pop-up-warning.visible {
  right: -5px;
}
#pop-up-warning.animation-out {
  right: -100%;
}

#hero {
    margin-bottom: 6rem;
}

#hero img {
    max-width: 1000px;
    width: 100%;
}

#hero img.desktop {
    display: block;
}

#hero img.mobile {
    display: none;
}

@media only screen and (max-width: 1010px) {

    #hero {
        margin-bottom: 2rem;
    }

  }
  
  @media only screen and (max-width: 800px) {

    #hero {
        width: 100%;
    }

    #hero img.desktop {
        display: none;
    }
    
    #hero img.mobile {
        display: block;
    }
  }
.alkimi-card.video {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* padding: 20px; */
}

.alkimi-card.video h1 {
  color: #41c2d2;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  /* font-style: italic; */
}

.alkimi-card.video p {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  /* font-style: italic; */
}

.alkimi-card.video .right {
  padding-left: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.alkimi-card.video img {
  width: 324px;
  height: 294px;
}

@media only screen and (max-width: 1010px) {
  .alkimi-card.video {
    grid-template-columns: 1fr;
    margin-bottom: 4rem;
  }

  .alkimi-card.video h1 {
    font-size: 1.8rem;
  }

  .alkimi-card.video p {
    font-size: 1.2rem;
  }

  .alkimi-card.video img {
    width: 100%;
    height: 100%;
  }

  .alkimi-card.video .left {
    padding: 2rem 2rem 0rem 2rem;
  }

  .alkimi-card.video .right {
    /* padding-top: 20px; */
    padding: 0rem 2rem 2rem 2rem;
  }
}

@media only screen and (max-width: 650px) {
}

#game {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* padding: 20px; */
    max-width: 1000px;
    /* background-color: #081433; */
    background-image: url(/static/media/game-5.a444d4e4.png);
    background-size: cover;
    border-top: 6px solid #48bcd0;
    margin-bottom: 6rem;
}

#game h1 {
    color: #41C2D2;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    /* font-style: italic; */
}

#game p {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    /* font-style: italic; */

}

#game p.third {
    margin-bottom: 0rem;
}

#game p a {
    color: #48bcd0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

#game .left {
    padding: 40px;
}

#game .right {
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#game img {
    width: 324px;
    height: 294px;
}



@media only screen and (max-width: 1010px) {

    #game {
        grid-template-columns: 1fr;
        margin-bottom: 4rem;
    }

    #game h1 {
        font-size: 1.8rem;
    }

    #game p {
        font-size: 1.2rem; 
    }

    #game p.third {
        margin-bottom: 2rem;
    }

    #game img {
        width: 100%;
        height: 100%;
    }

    #game .left {
        padding: 0rem 2rem 0rem 2rem;

    }

    #game .right {
        /* padding-top: 20px; */
        padding: 2rem 2rem 2rem 2rem;
    }

  }
  
  @media only screen and (max-width: 650px) {

  }
#gallery {
    margin-bottom: 6rem;
    width: 1200px;
    /* background-color: red; */
}

#gallery img {
    /* max-width: 340px; */
    width: 32rem;
}
/* 
.carouselone {
    width: 1000px;
    background-color: red;
} */

.card {
    width: 300px;
    height: 300px;
    background: #333;
    color: #fff;
    font-size: 30px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.jMNKZk button {
    background-color: rgba(103,58,183,1);
    color: #fff;
}

.sc-iCfMLu button {
    background-color: rgba(103,58,183,1);
    color: #fff;
}

.fVgyLU {
    background-color: rgba(103,58,183,1)!important;
    color: #fff!important;
    transition: none!important;
}

.fViXLK {
    background-color: rgba(103,58,183,1)!important;
    color: #fff!important;
    transition: none!important;
}

/* 
.sc-bdvvtL {
    background-color: rgba(103,58,183,1);
    color: #fff;
} */



.sc-iCfMLu button.lcUbOd {
    box-shadow: none!important;
    background-color: rgb(255, 255, 255)!important;
}

.bBjxWM {
    box-shadow: none!important;
    background-color: rgb(255, 255, 255)!important;
}

button .bBjxWM {
    box-shadow: none!important;
    background-color: rgb(255, 255, 255)!important;
}


@media only screen and (max-width: 1200px) {

    #gallery {
        width: 100%;
    }

    #gallery img {
        /* max-width: 320px; */
        max-width: 30rem;
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }

  }



@media only screen and (max-width: 1010px) {

    #gallery {
        margin-bottom: 4rem;
    }

  }

  @media only screen and (max-width: 927px) {

    #gallery img {
        max-width: none;
        width: 100%;
    }

    .sc-bdvvtL {
        display: none!important;
    }

  }
  
  @media only screen and (max-width: 650px) {
    #gallery img {
        padding-left: none;
        padding-right: none;
    }
  }
#card {
    display: grid;
    grid-template-columns: 1fr;
    padding: 40px;
    max-width: 1000px;
    background-color: #081433;
    border-top: 6px solid #48bcd0;
    margin-bottom: 6rem;
    width: 100%;
}

#card h1 {
    color: #41C2D2;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    /* font-style: italic; */
}

#card p {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    /* font-style: italic; */
}

#card a {
    color: #41C2D2;
}

#card p.last {
    margin-bottom: 0rem;
}

#card .table {
    text-align: center;
}

#card .table img {
    margin-top: 4rem;
    /* width: 100%; */
}



@media only screen and (max-width: 1010px) {

    #card {
        margin-bottom: 4rem;
    }

    #card .table img {
        width: 100%;
    }

  }
  
  @media only screen and (max-width: 800px) {
    #card {
        padding: 2rem;
    }

    #card h1 {
        font-size: 1.8rem;
    }

    #card p {
        font-size: 1.2rem; 
    }
  }
#community {
    width: 1000px;
    background-color: #41C2D2;
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* border-top: 6px solid #48bcd0; */
    margin-bottom: 6rem;
    text-align: center;
    /* vertical-align: middle; */
}

#community h1 {
    color: #081433;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    /* font-style: italic; */
}

#community a {
    text-decoration: none;
}

/* #community .one {
    vertical-align: middle;
    display: inline-block;
}

#community .one h1 {
    vertical-align: middle;
} */

#community button {
    display: inline-block;
    /* min-width: 200px; */
    padding: 14px;
    color: #fff;
    font-size: 12px;
    border: none;
    background-color: #081433;
}

#community button img {
    width: 20px;
    vertical-align: middle;
    margin: 0px 10px 0px 0px;
}

#community button .btn-text {
    vertical-align: middle;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    /* font-style: italic; */
}

#community button:hover {
    background-color: #102866;
}



@media only screen and (max-width: 1010px) {

    #community {
        width: 100%;
        grid-template-columns: 1fr;
        margin-bottom: 4rem;
    }

    #community h1 {
        margin-bottom: 2rem;
    }

    #community button {
        min-width: 200px;
    }

    #community .two button {
        margin-bottom: 2rem; 
    }

    #community .three button {
        margin-bottom: 2rem; 
    }

  }
  
  @media only screen and (max-width: 650px) {

  }
#faq {
  /* display: grid;
    grid-template-columns: 1fr; */
  padding: 40px;
  max-width: 1000px;
  width: 100%;
  background-color: #081433;
  border-top: 6px solid #48bcd0;
  margin-bottom: 6rem;
}

#faq h1 {
  color: #41c2d2;
  font-size: 2rem;
  margin-bottom: 4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-style: italic;
}

#faq p {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  /* font-style: italic; */
}

#faq p a {
  color: #41c2d2;
}

.accordion {
  border-radius: 0px !important;
  border: 0px !important;
}

.accordion-item {
  border: none;
  border-top: 3px solid #48bcd0 !important;
  background-color: inherit !important;
}

.accordion-item button {
  color: #fff !important;
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  /* font-style: italic; */
}

.accordion .accordion-item .title:after {
  color: #fff !important;
  font-size: 2rem !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
  content: "\002b" !important;
}

.accordion .accordion-item.active .title:after {
  color: #fff !important;
  font-size: 2rem !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
  content: "\2212" !important;
}

/* button.title::after {
    color: #fff!important;
    text-shadow: 0 0 0 #48bcd0;
} */

.accordion .accordion-item .title {
  background-color: #081433 !important;
  padding: 18px 0px !important;
}

/* .accordion .accordion-item .title p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
} */

.accordion .accordion-item:first-child .title {
  border-radius: 0px !important;
}

.accordion .accordion-item:last-child .title {
  border-radius: 0px !important;
}

@media only screen and (max-width: 1010px) {
  #faq {
    margin-bottom: 2rem;
    padding: 2rem;
  }
}

@media only screen and (max-width: 800px) {
  .accordion-item button {
    font-size: 1.2rem;
  }

  #faq p {
    font-size: 1.2rem;
  }
}

#footer {
  display: grid;
  min-height: 100px;
  grid-auto-flow: column;
  background-color: none;
  grid-gap: 1.5em;
  padding: 0 0px;
  min-width: 1000px;
  /* background-color: red; */
  /* margin-bottom: 4rem; */
}

#logo {
  display: grid;
  justify-content: start;
  align-content: center;
}

.logo img {
  width: 25rem;
}

#links {
  display: grid;
  justify-content: right;
  align-content: center;
  grid-auto-flow: column;
  grid-gap: 1.5em;
  font-family: "Open Sans", sans-serif;
}

#links button {
  min-width: 50px;
  min-height: 50px;
}

#links button.social {
  background: none;
  padding: 8px 15px;
  /* border-image-source: linear-gradient(to left, #354E8F, #48BCD0); */
  /* opacity: .3;
    transition: 3s; */
}

#links button.social:hover {
  /* background: linear-gradient(90.83deg, #48BCD0 0.36%, #354E8F 99.77%); */
  /* opacity: 1; */
  background-color: #48bcd0 !important;
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
}

#links button.claim-nft {
  border: none !important;
  background: #48bcd0;
  color: #fff;
  padding: 8px 40px;
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

#links img {
  vertical-align: middle;
}

a:hover {
  color: #0474bc;
}

@media only screen and (max-width: 1010px) {
  #footer {
    min-width: 0px;
    width: 100%;
    grid-gap: 0rem;
  }

  #links a {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  #footer {
    min-width: 0px;
    width: 100%;
  }

  .logo img {
    width: 20rem;
  }

  #links button.social {
    display: none;
  }
}

.alkimi-card {
  padding: 40px;
  max-width: 1000px;
  background-color: #081433;
  border-top: 6px solid #48bcd0;
  margin-bottom: 6rem;
  width: 100%;
  color: #fff;
}

.alkimi-card h2 {
  color: #41c2d2;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  /* font-style: italic; */
}

.alkimi-card p {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  /* font-style: italic; */
}

.txt-center {
  text-align: center;
}

@media only screen and (max-width: 1010px) {
  .alkimi-card h2 {
    font-size: 1.8rem;
  }

  .alkimi-card p {
    font-size: 1.2rem;
  }

  .alkimi-card {
    /* padding-top: 20px; */
    padding: 2rem;
  }
}

.elementals-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 3px;
  gap: 3px;
}
.elementals-container .element {
  max-width: 150px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}
.elementals-container .element:not(.selected) {
  opacity: 0.4;
}
.elementals-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
}
@media only screen and (max-width: 650px) {
  .elementals-container .element {
    max-width: 75px;
  }
}
.selected-el {
  color: #41c2d2;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  margin-bottom: 0 !important;
}
.selected-el-nr {
  color: #41c2d2;
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}

.box-container {
  border: solid 3px #48bcd0;
  width: 100%;
  max-width: 1000px;
  padding: 5rem 4rem;
  margin: 0 auto;
  color: #fff;
  position: relative;
  background: #091532;
  margin-bottom: 5rem;
}
.box-container .inner {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.nr-circle {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #48bcd0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
}
.carouselone img {
  max-width: 400px;
  width: 100%;
}
.box-container .title {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 2rem auto;
}
.box-container .box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 5rem;
  gap: 5rem;
}
.box-container .elements-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  /* padding: 0rem; */
}
.box-container .elements-container img:hover {
  cursor: pointer;
  opacity: 0.9;
}
.elements-container img:not(.selected) {
  opacity: 0.4;
  transition: all 0.3s ease-in-out;
}
.loading {
  margin: auto;
  font-size: 2rem;
}
.nft-carousel .rec-carousel {
  padding: 0 15rem;
}
@media only screen and (max-width: 650px) {
  .nft-carousel .rec-carousel {
    padding: 0;
  }
}
.rec-arrow:disabled {
  opacity: 0.3;
}
.rec-arrow:hover {
  color: rgb(70, 177, 201) !important;
}
.get-alex-container {
  display: flex;
  justify-content: center;
  grid-gap: 35px;
  gap: 35px;
}
.experiment-btns-container {
  display: flex;
  justify-items: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.experiment-btns-container .btn-default {
  margin: 0;
}
.experiment-btns-container .btn-default:first-of-type {
  margin-left: auto;
}
.experiment-btns-container .btn-default:last-of-type {
  margin-right: auto;
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 2;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background-color: rgba(9, 21, 50, 0.9);
}
#overlay .close {
  position: absolute;
  top: 1rem;
  right: 2rem;
  width: 2rem;
  cursor: pointer;
  background: none;
  border: none;
  color: #fff;
  font-size: 3rem;
}
#overlay h3 {
  margin-bottom: 2rem;
}
#overlay .img-container {
  width: 100%;
  max-width: 1000px;
  max-height: 70%;
  margin: 0 auto;
  padding: 0 10rem;
  color: #fff;
  position: relative;
  background: #091532;
}
#overlay .img-container img {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.new-alex h2 {
  color: #41c2d2;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}
.jump-in {
  position: relative;
  -webkit-animation: jump-in 1s cubic-bezier(0.3, 2.2, 0.5, 1);
          animation: jump-in 1s cubic-bezier(0.3, 2.2, 0.5, 1);
  width: 100%;
  max-width: 600px;
  height: auto;
  padding: 0 2rem;
}

@-webkit-keyframes jump-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes jump-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes move {
  100% {
    -webkit-transform: translate3d(250px, 250px, -1000px);
            transform: translate3d(250px, 250px, -1000px);
  }
}
@keyframes move {
  100% {
    -webkit-transform: translate3d(250px, 250px, -1000px);
            transform: translate3d(250px, 250px, -1000px);
  }
}
@media only screen and (max-width: 650px) {
  @-webkit-keyframes move {
    100% {
      -webkit-transform: translate3d(150px, 250px, -1000px);
              transform: translate3d(150px, 250px, -1000px);
    }
  }
  @keyframes move {
    100% {
      -webkit-transform: translate3d(150px, 250px, -1000px);
              transform: translate3d(150px, 250px, -1000px);
    }
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
  }
}
@keyframes fade {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
  }
}
.animation-container {
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.animation-container.fade {
  -webkit-animation: fade 0.8s ease-in-out;
          animation: fade 0.8s ease-in-out;
}
.bubble-wrap {
  margin: auto;
  width: 500px;
  height: 500px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-perspective: 600px;
          perspective: 600px;
}
.bubble {
  position: absolute;
  background: black;
  opacity: 0.7;
  border-radius: 50%;
  -webkit-animation: move 3s infinite;
          animation: move 3s infinite;
}
.bubble:nth-child(1) {
  height: 18px;
  width: 18px;
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  -webkit-transform: translate3d(643px, 854px, 1290px);
          transform: translate3d(643px, 854px, 1290px);
  background: #d9267a;
}
.bubble:nth-child(2) {
  height: 30px;
  width: 30px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  -webkit-transform: translate3d(78px, 593px, 1629px);
          transform: translate3d(78px, 593px, 1629px);
  background: #d92691;
}
.bubble:nth-child(3) {
  height: 30px;
  width: 30px;
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  -webkit-transform: translate3d(470px, 477px, 1495px);
          transform: translate3d(470px, 477px, 1495px);
  background: #d92641;
}
.bubble:nth-child(4) {
  height: 27px;
  width: 27px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  -webkit-transform: translate3d(769px, 151px, 1070px);
          transform: translate3d(769px, 151px, 1070px);
  background: #ca26d9;
}
.bubble:nth-child(5) {
  height: 13px;
  width: 13px;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  -webkit-transform: translate3d(910px, 116px, 1607px);
          transform: translate3d(910px, 116px, 1607px);
  background: #26afd9;
}
.bubble:nth-child(6) {
  height: 16px;
  width: 16px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
  -webkit-transform: translate3d(887px, 303px, 1103px);
          transform: translate3d(887px, 303px, 1103px);
  background: #d926ca;
}
.bubble:nth-child(7) {
  height: 29px;
  width: 29px;
  -webkit-animation-delay: -1.4s;
          animation-delay: -1.4s;
  -webkit-transform: translate3d(243px, 274px, 752px);
          transform: translate3d(243px, 274px, 752px);
  background: #26afd9;
}
.bubble:nth-child(8) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
  -webkit-transform: translate3d(190px, 642px, 1448px);
          transform: translate3d(190px, 642px, 1448px);
  background: #d93b26;
}
.bubble:nth-child(9) {
  height: 26px;
  width: 26px;
  -webkit-animation-delay: -1.8s;
          animation-delay: -1.8s;
  -webkit-transform: translate3d(246px, 902px, 1671px);
          transform: translate3d(246px, 902px, 1671px);
  background: #4d26d9;
}
.bubble:nth-child(10) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  -webkit-transform: translate3d(514px, 695px, 507px);
          transform: translate3d(514px, 695px, 507px);
  background: #26d0d9;
}
.bubble:nth-child(11) {
  height: 28px;
  width: 28px;
  -webkit-animation-delay: -2.2s;
          animation-delay: -2.2s;
  -webkit-transform: translate3d(857px, 568px, 969px);
          transform: translate3d(857px, 568px, 969px);
  background: #2688d9;
}
.bubble:nth-child(12) {
  height: 16px;
  width: 16px;
  -webkit-animation-delay: -2.4s;
          animation-delay: -2.4s;
  -webkit-transform: translate3d(851px, 295px, 1027px);
          transform: translate3d(851px, 295px, 1027px);
  background: #2650d9;
}
.bubble:nth-child(13) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -2.6s;
          animation-delay: -2.6s;
  -webkit-transform: translate3d(433px, 977px, 1556px);
          transform: translate3d(433px, 977px, 1556px);
  background: #d9b526;
}
.bubble:nth-child(14) {
  height: 23px;
  width: 23px;
  -webkit-animation-delay: -2.8s;
          animation-delay: -2.8s;
  -webkit-transform: translate3d(734px, 244px, 677px);
          transform: translate3d(734px, 244px, 677px);
  background: #d92644;
}
.bubble:nth-child(15) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  -webkit-transform: translate3d(661px, 986px, 625px);
          transform: translate3d(661px, 986px, 625px);
  background: #26d96e;
}
.bubble:nth-child(16) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -3.2s;
          animation-delay: -3.2s;
  -webkit-transform: translate3d(817px, 338px, 1693px);
          transform: translate3d(817px, 338px, 1693px);
  background: #d92682;
}
.bubble:nth-child(17) {
  height: 18px;
  width: 18px;
  -webkit-animation-delay: -3.4s;
          animation-delay: -3.4s;
  -webkit-transform: translate3d(206px, 360px, 75px);
          transform: translate3d(206px, 360px, 75px);
  background: #d92641;
}
.bubble:nth-child(18) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -3.6s;
          animation-delay: -3.6s;
  -webkit-transform: translate3d(823px, 337px, 568px);
          transform: translate3d(823px, 337px, 568px);
  background: #8bd926;
}
.bubble:nth-child(19) {
  height: 13px;
  width: 13px;
  -webkit-animation-delay: -3.8s;
          animation-delay: -3.8s;
  -webkit-transform: translate3d(493px, 999px, 1252px);
          transform: translate3d(493px, 999px, 1252px);
  background: #d926d3;
}
.bubble:nth-child(20) {
  height: 25px;
  width: 25px;
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  -webkit-transform: translate3d(234px, 46px, 845px);
          transform: translate3d(234px, 46px, 845px);
  background: #44d926;
}
.bubble:nth-child(21) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -4.2s;
          animation-delay: -4.2s;
  -webkit-transform: translate3d(388px, 589px, 1632px);
          transform: translate3d(388px, 589px, 1632px);
  background: #80d926;
}
.bubble:nth-child(22) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -4.4s;
          animation-delay: -4.4s;
  -webkit-transform: translate3d(602px, 20px, 309px);
          transform: translate3d(602px, 20px, 309px);
  background: #2653d9;
}
.bubble:nth-child(23) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -4.6s;
          animation-delay: -4.6s;
  -webkit-transform: translate3d(120px, 84px, 1700px);
          transform: translate3d(120px, 84px, 1700px);
  background: #2653d9;
}
.bubble:nth-child(24) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -4.8s;
          animation-delay: -4.8s;
  -webkit-transform: translate3d(773px, 227px, 1159px);
          transform: translate3d(773px, 227px, 1159px);
  background: #d96826;
}
.bubble:nth-child(25) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  -webkit-transform: translate3d(275px, 605px, 390px);
          transform: translate3d(275px, 605px, 390px);
  background: #9d26d9;
}
.bubble:nth-child(26) {
  height: 29px;
  width: 29px;
  -webkit-animation-delay: -5.2s;
          animation-delay: -5.2s;
  -webkit-transform: translate3d(161px, 725px, 1925px);
          transform: translate3d(161px, 725px, 1925px);
  background: #26d97a;
}
.bubble:nth-child(27) {
  height: 17px;
  width: 17px;
  -webkit-animation-delay: -5.4s;
          animation-delay: -5.4s;
  -webkit-transform: translate3d(503px, 95px, 709px);
          transform: translate3d(503px, 95px, 709px);
  background: #d026d9;
}
.bubble:nth-child(28) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -5.6s;
          animation-delay: -5.6s;
  -webkit-transform: translate3d(613px, 900px, 1558px);
          transform: translate3d(613px, 900px, 1558px);
  background: #d026d9;
}
.bubble:nth-child(29) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -5.8s;
          animation-delay: -5.8s;
  -webkit-transform: translate3d(328px, 745px, 1663px);
          transform: translate3d(328px, 745px, 1663px);
  background: #d926ca;
}
.bubble:nth-child(30) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -6s;
          animation-delay: -6s;
  -webkit-transform: translate3d(336px, 108px, 1214px);
          transform: translate3d(336px, 108px, 1214px);
  background: #d926c4;
}
.bubble:nth-child(31) {
  height: 14px;
  width: 14px;
  -webkit-animation-delay: -6.2s;
          animation-delay: -6.2s;
  -webkit-transform: translate3d(652px, 916px, 223px);
          transform: translate3d(652px, 916px, 223px);
  background: #d99426;
}
.bubble:nth-child(32) {
  height: 30px;
  width: 30px;
  -webkit-animation-delay: -6.4s;
          animation-delay: -6.4s;
  -webkit-transform: translate3d(128px, 299px, 564px);
          transform: translate3d(128px, 299px, 564px);
  background: #d9265f;
}
.bubble:nth-child(33) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -6.6s;
          animation-delay: -6.6s;
  -webkit-transform: translate3d(899px, 583px, 1716px);
          transform: translate3d(899px, 583px, 1716px);
  background: #d9262c;
}
.bubble:nth-child(34) {
  height: 30px;
  width: 30px;
  -webkit-animation-delay: -6.8s;
          animation-delay: -6.8s;
  -webkit-transform: translate3d(734px, 374px, 383px);
          transform: translate3d(734px, 374px, 383px);
  background: #d99a26;
}
.bubble:nth-child(35) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -7s;
          animation-delay: -7s;
  -webkit-transform: translate3d(924px, 87px, 1815px);
          transform: translate3d(924px, 87px, 1815px);
  background: #ac26d9;
}
.bubble:nth-child(36) {
  height: 18px;
  width: 18px;
  -webkit-animation-delay: -7.2s;
          animation-delay: -7.2s;
  -webkit-transform: translate3d(133px, 548px, 863px);
          transform: translate3d(133px, 548px, 863px);
  background: #8026d9;
}
.bubble:nth-child(37) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -7.4s;
          animation-delay: -7.4s;
  -webkit-transform: translate3d(63px, 237px, 1122px);
          transform: translate3d(63px, 237px, 1122px);
  background: #9426d9;
}
.bubble:nth-child(38) {
  height: 13px;
  width: 13px;
  -webkit-animation-delay: -7.6s;
          animation-delay: -7.6s;
  -webkit-transform: translate3d(431px, 424px, 276px);
          transform: translate3d(431px, 424px, 276px);
  background: #26d94d;
}
.bubble:nth-child(39) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -7.8s;
          animation-delay: -7.8s;
  -webkit-transform: translate3d(840px, 236px, 1622px);
          transform: translate3d(840px, 236px, 1622px);
  background: #26d97d;
}
.bubble:nth-child(40) {
  height: 13px;
  width: 13px;
  -webkit-animation-delay: -8s;
          animation-delay: -8s;
  -webkit-transform: translate3d(374px, 146px, 1474px);
          transform: translate3d(374px, 146px, 1474px);
  background: #5326d9;
}
.bubble:nth-child(41) {
  height: 23px;
  width: 23px;
  -webkit-animation-delay: -8.2s;
          animation-delay: -8.2s;
  -webkit-transform: translate3d(951px, 377px, 510px);
          transform: translate3d(951px, 377px, 510px);
  background: #26d9d9;
}
.bubble:nth-child(42) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -8.4s;
          animation-delay: -8.4s;
  -webkit-transform: translate3d(19px, 223px, 1075px);
          transform: translate3d(19px, 223px, 1075px);
  background: #a6d926;
}
.bubble:nth-child(43) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -8.6s;
          animation-delay: -8.6s;
  -webkit-transform: translate3d(884px, 982px, 1133px);
          transform: translate3d(884px, 982px, 1133px);
  background: #d94426;
}
.bubble:nth-child(44) {
  height: 22px;
  width: 22px;
  -webkit-animation-delay: -8.8s;
          animation-delay: -8.8s;
  -webkit-transform: translate3d(779px, 222px, 1029px);
          transform: translate3d(779px, 222px, 1029px);
  background: #268ed9;
}
.bubble:nth-child(45) {
  height: 24px;
  width: 24px;
  -webkit-animation-delay: -9s;
          animation-delay: -9s;
  -webkit-transform: translate3d(287px, 25px, 910px);
          transform: translate3d(287px, 25px, 910px);
  background: #26a3d9;
}
.bubble:nth-child(46) {
  height: 18px;
  width: 18px;
  -webkit-animation-delay: -9.2s;
          animation-delay: -9.2s;
  -webkit-transform: translate3d(607px, 886px, 88px);
          transform: translate3d(607px, 886px, 88px);
  background: #d9b526;
}
.bubble:nth-child(47) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -9.4s;
          animation-delay: -9.4s;
  -webkit-transform: translate3d(868px, 488px, 1074px);
          transform: translate3d(868px, 488px, 1074px);
  background: #d99426;
}
.bubble:nth-child(48) {
  height: 26px;
  width: 26px;
  -webkit-animation-delay: -9.6s;
          animation-delay: -9.6s;
  -webkit-transform: translate3d(2px, 502px, 1140px);
          transform: translate3d(2px, 502px, 1140px);
  background: #d94a26;
}
.bubble:nth-child(49) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -9.8s;
          animation-delay: -9.8s;
  -webkit-transform: translate3d(304px, 940px, 484px);
          transform: translate3d(304px, 940px, 484px);
  background: #d9269d;
}
.bubble:nth-child(50) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -10s;
          animation-delay: -10s;
  -webkit-transform: translate3d(989px, 403px, 527px);
          transform: translate3d(989px, 403px, 527px);
  background: #5c26d9;
}
.bubble:nth-child(51) {
  height: 22px;
  width: 22px;
  -webkit-animation-delay: -10.2s;
          animation-delay: -10.2s;
  -webkit-transform: translate3d(137px, 873px, 799px);
          transform: translate3d(137px, 873px, 799px);
  background: #8526d9;
}
.bubble:nth-child(52) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -10.4s;
          animation-delay: -10.4s;
  -webkit-transform: translate3d(127px, 281px, 786px);
          transform: translate3d(127px, 281px, 786px);
  background: #26d929;
}
.bubble:nth-child(53) {
  height: 27px;
  width: 27px;
  -webkit-animation-delay: -10.6s;
          animation-delay: -10.6s;
  -webkit-transform: translate3d(938px, 899px, 1185px);
          transform: translate3d(938px, 899px, 1185px);
  background: #ac26d9;
}
.bubble:nth-child(54) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -10.8s;
          animation-delay: -10.8s;
  -webkit-transform: translate3d(424px, 256px, 738px);
          transform: translate3d(424px, 256px, 738px);
  background: #bbd926;
}
.bubble:nth-child(55) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -11s;
          animation-delay: -11s;
  -webkit-transform: translate3d(915px, 229px, 310px);
          transform: translate3d(915px, 229px, 310px);
  background: #d96226;
}
.bubble:nth-child(56) {
  height: 21px;
  width: 21px;
  -webkit-animation-delay: -11.2s;
          animation-delay: -11.2s;
  -webkit-transform: translate3d(33px, 305px, 413px);
          transform: translate3d(33px, 305px, 413px);
  background: #cad926;
}
.bubble:nth-child(57) {
  height: 30px;
  width: 30px;
  -webkit-animation-delay: -11.4s;
          animation-delay: -11.4s;
  -webkit-transform: translate3d(766px, 108px, 1673px);
          transform: translate3d(766px, 108px, 1673px);
  background: #d92668;
}
.bubble:nth-child(58) {
  height: 19px;
  width: 19px;
  -webkit-animation-delay: -11.6s;
          animation-delay: -11.6s;
  -webkit-transform: translate3d(164px, 978px, 1868px);
          transform: translate3d(164px, 978px, 1868px);
  background: #5fd926;
}
.bubble:nth-child(59) {
  height: 27px;
  width: 27px;
  -webkit-animation-delay: -11.8s;
          animation-delay: -11.8s;
  -webkit-transform: translate3d(9px, 725px, 1528px);
          transform: translate3d(9px, 725px, 1528px);
  background: #26d994;
}
.bubble:nth-child(60) {
  height: 13px;
  width: 13px;
  -webkit-animation-delay: -12s;
          animation-delay: -12s;
  -webkit-transform: translate3d(413px, 670px, 850px);
          transform: translate3d(413px, 670px, 850px);
  background: #26d93e;
}
.bubble:nth-child(61) {
  height: 27px;
  width: 27px;
  -webkit-animation-delay: -12.2s;
          animation-delay: -12.2s;
  -webkit-transform: translate3d(926px, 425px, 276px);
          transform: translate3d(926px, 425px, 276px);
  background: #d9a026;
}
.bubble:nth-child(62) {
  height: 20px;
  width: 20px;
  -webkit-animation-delay: -12.4s;
          animation-delay: -12.4s;
  -webkit-transform: translate3d(259px, 811px, 1157px);
          transform: translate3d(259px, 811px, 1157px);
  background: #26afd9;
}
.bubble:nth-child(63) {
  height: 17px;
  width: 17px;
  -webkit-animation-delay: -12.6s;
          animation-delay: -12.6s;
  -webkit-transform: translate3d(350px, 282px, 780px);
          transform: translate3d(350px, 282px, 780px);
  background: #8026d9;
}
.bubble:nth-child(64) {
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -12.8s;
          animation-delay: -12.8s;
  -webkit-transform: translate3d(123px, 922px, 148px);
          transform: translate3d(123px, 922px, 148px);
  background: #d9a626;
}
.bubble:nth-child(65) {
  height: 22px;
  width: 22px;
  -webkit-animation-delay: -13s;
          animation-delay: -13s;
  -webkit-transform: translate3d(528px, 309px, 772px);
          transform: translate3d(528px, 309px, 772px);
  background: #d92650;
}
.bubble:nth-child(66) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -13.2s;
          animation-delay: -13.2s;
  -webkit-transform: translate3d(771px, 295px, 191px);
          transform: translate3d(771px, 295px, 191px);
  background: #b8d926;
}
.bubble:nth-child(67) {
  height: 18px;
  width: 18px;
  -webkit-animation-delay: -13.4s;
          animation-delay: -13.4s;
  -webkit-transform: translate3d(370px, 489px, 1455px);
          transform: translate3d(370px, 489px, 1455px);
  background: #5c26d9;
}
.bubble:nth-child(68) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -13.6s;
          animation-delay: -13.6s;
  -webkit-transform: translate3d(794px, 316px, 1576px);
          transform: translate3d(794px, 316px, 1576px);
  background: #5626d9;
}
.bubble:nth-child(69) {
  height: 17px;
  width: 17px;
  -webkit-animation-delay: -13.8s;
          animation-delay: -13.8s;
  -webkit-transform: translate3d(85px, 346px, 1511px);
          transform: translate3d(85px, 346px, 1511px);
  background: #d92f26;
}
.bubble:nth-child(70) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -14s;
          animation-delay: -14s;
  -webkit-transform: translate3d(137px, 392px, 1684px);
          transform: translate3d(137px, 392px, 1684px);
  background: #2688d9;
}
.bubble:nth-child(71) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -14.2s;
          animation-delay: -14.2s;
  -webkit-transform: translate3d(398px, 444px, 1717px);
          transform: translate3d(398px, 444px, 1717px);
  background: #d9267d;
}
.bubble:nth-child(72) {
  height: 20px;
  width: 20px;
  -webkit-animation-delay: -14.4s;
          animation-delay: -14.4s;
  -webkit-transform: translate3d(909px, 462px, 748px);
          transform: translate3d(909px, 462px, 748px);
  background: #d9262f;
}
.bubble:nth-child(73) {
  height: 21px;
  width: 21px;
  -webkit-animation-delay: -14.6s;
          animation-delay: -14.6s;
  -webkit-transform: translate3d(787px, 517px, 922px);
          transform: translate3d(787px, 517px, 922px);
  background: #d9268e;
}
.bubble:nth-child(74) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -14.8s;
          animation-delay: -14.8s;
  -webkit-transform: translate3d(731px, 848px, 21px);
          transform: translate3d(731px, 848px, 21px);
  background: #26d6d9;
}
.bubble:nth-child(75) {
  height: 23px;
  width: 23px;
  -webkit-animation-delay: -15s;
          animation-delay: -15s;
  -webkit-transform: translate3d(551px, 188px, 377px);
          transform: translate3d(551px, 188px, 377px);
  background: #5f26d9;
}
.bubble:nth-child(76) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -15.2s;
          animation-delay: -15.2s;
  -webkit-transform: translate3d(503px, 48px, 1544px);
          transform: translate3d(503px, 48px, 1544px);
  background: #d926c1;
}
.bubble:nth-child(77) {
  height: 25px;
  width: 25px;
  -webkit-animation-delay: -15.4s;
          animation-delay: -15.4s;
  -webkit-transform: translate3d(620px, 370px, 231px);
          transform: translate3d(620px, 370px, 231px);
  background: #ac26d9;
}
.bubble:nth-child(78) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -15.6s;
          animation-delay: -15.6s;
  -webkit-transform: translate3d(927px, 449px, 30px);
          transform: translate3d(927px, 449px, 30px);
  background: #26d99a;
}
.bubble:nth-child(79) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -15.8s;
          animation-delay: -15.8s;
  -webkit-transform: translate3d(782px, 561px, 1441px);
          transform: translate3d(782px, 561px, 1441px);
  background: #d92635;
}
.bubble:nth-child(80) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -16s;
          animation-delay: -16s;
  -webkit-transform: translate3d(666px, 143px, 156px);
          transform: translate3d(666px, 143px, 156px);
  background: #d9b826;
}
.bubble:nth-child(81) {
  height: 27px;
  width: 27px;
  -webkit-animation-delay: -16.2s;
          animation-delay: -16.2s;
  -webkit-transform: translate3d(704px, 270px, 1330px);
          transform: translate3d(704px, 270px, 1330px);
  background: #d9d026;
}
.bubble:nth-child(82) {
  height: 18px;
  width: 18px;
  -webkit-animation-delay: -16.4s;
          animation-delay: -16.4s;
  -webkit-transform: translate3d(959px, 899px, 621px);
          transform: translate3d(959px, 899px, 621px);
  background: #d92f26;
}
.bubble:nth-child(83) {
  height: 25px;
  width: 25px;
  -webkit-animation-delay: -16.6s;
          animation-delay: -16.6s;
  -webkit-transform: translate3d(957px, 718px, 1977px);
          transform: translate3d(957px, 718px, 1977px);
  background: #a626d9;
}
.bubble:nth-child(84) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -16.8s;
          animation-delay: -16.8s;
  -webkit-transform: translate3d(98px, 382px, 1580px);
          transform: translate3d(98px, 382px, 1580px);
  background: #26d97d;
}
.bubble:nth-child(85) {
  height: 27px;
  width: 27px;
  -webkit-animation-delay: -17s;
          animation-delay: -17s;
  -webkit-transform: translate3d(874px, 288px, 1907px);
          transform: translate3d(874px, 288px, 1907px);
  background: #26d9af;
}
.bubble:nth-child(86) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -17.2s;
          animation-delay: -17.2s;
  -webkit-transform: translate3d(395px, 381px, 719px);
          transform: translate3d(395px, 381px, 719px);
  background: #d98226;
}
.bubble:nth-child(87) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -17.4s;
          animation-delay: -17.4s;
  -webkit-transform: translate3d(258px, 602px, 980px);
          transform: translate3d(258px, 602px, 980px);
  background: #d9af26;
}
.bubble:nth-child(88) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -17.6s;
          animation-delay: -17.6s;
  -webkit-transform: translate3d(650px, 494px, 636px);
          transform: translate3d(650px, 494px, 636px);
  background: #cdd926;
}
.bubble:nth-child(89) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -17.8s;
          animation-delay: -17.8s;
  -webkit-transform: translate3d(81px, 963px, 1775px);
          transform: translate3d(81px, 963px, 1775px);
  background: #4dd926;
}
.bubble:nth-child(90) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -18s;
          animation-delay: -18s;
  -webkit-transform: translate3d(880px, 422px, 1591px);
          transform: translate3d(880px, 422px, 1591px);
  background: #26d92c;
}
.bubble:nth-child(91) {
  height: 29px;
  width: 29px;
  -webkit-animation-delay: -18.2s;
          animation-delay: -18.2s;
  -webkit-transform: translate3d(831px, 928px, 422px);
          transform: translate3d(831px, 928px, 422px);
  background: #d92682;
}
.bubble:nth-child(92) {
  height: 26px;
  width: 26px;
  -webkit-animation-delay: -18.4s;
          animation-delay: -18.4s;
  -webkit-transform: translate3d(102px, 830px, 545px);
          transform: translate3d(102px, 830px, 545px);
  background: #32d926;
}
.bubble:nth-child(93) {
  height: 16px;
  width: 16px;
  -webkit-animation-delay: -18.6s;
          animation-delay: -18.6s;
  -webkit-transform: translate3d(208px, 686px, 523px);
          transform: translate3d(208px, 686px, 523px);
  background: #26d9b2;
}
.bubble:nth-child(94) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -18.8s;
          animation-delay: -18.8s;
  -webkit-transform: translate3d(6px, 737px, 670px);
          transform: translate3d(6px, 737px, 670px);
  background: #26d9d3;
}
.bubble:nth-child(95) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -19s;
          animation-delay: -19s;
  -webkit-transform: translate3d(545px, 440px, 1155px);
          transform: translate3d(545px, 440px, 1155px);
  background: #d026d9;
}
.bubble:nth-child(96) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -19.2s;
          animation-delay: -19.2s;
  -webkit-transform: translate3d(441px, 69px, 1062px);
          transform: translate3d(441px, 69px, 1062px);
  background: #26d99d;
}
.bubble:nth-child(97) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -19.4s;
          animation-delay: -19.4s;
  -webkit-transform: translate3d(133px, 281px, 1839px);
          transform: translate3d(133px, 281px, 1839px);
  background: #26d6d9;
}
.bubble:nth-child(98) {
  height: 30px;
  width: 30px;
  -webkit-animation-delay: -19.6s;
          animation-delay: -19.6s;
  -webkit-transform: translate3d(925px, 53px, 1540px);
          transform: translate3d(925px, 53px, 1540px);
  background: #d97a26;
}
.bubble:nth-child(99) {
  height: 15px;
  width: 15px;
  -webkit-animation-delay: -19.8s;
          animation-delay: -19.8s;
  -webkit-transform: translate3d(811px, 817px, 1612px);
          transform: translate3d(811px, 817px, 1612px);
  background: #d92c26;
}
.bubble:nth-child(100) {
  height: 21px;
  width: 21px;
  -webkit-animation-delay: -20s;
          animation-delay: -20s;
  -webkit-transform: translate3d(912px, 806px, 1985px);
          transform: translate3d(912px, 806px, 1985px);
  background: #d9bb26;
}

