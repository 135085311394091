*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:root {
  --bs-body-bg: none !important;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  background-image: url("./assets/img/background-4.png");
  background-color: #040a1a;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;0,900;1,600;1,900&display=swap");

.App {
  /* text-align: center; */
  margin: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* row-gap: 4rem; */
  border-top: 10px solid #48bcd0;
  border-bottom: 10px solid #48bcd0;
  /* border-bottom: 10px solid #48bcd0; */
  min-height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 2rem;
  padding-right: 2rem;
}

.App-logo {
  height: 8rem;
  width: 8rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
#gallery {
  max-width: 1000px;
  width: 100%;
}
.App-carousel {
  background-color: rgb(177, 177, 177);
  height: 45rem;
  width: 45rem;
}

@media only screen and (max-width: 1020px) {
  /* .App {
    align-items: inherit!important;
  } */
}
