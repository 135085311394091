#card {
    display: grid;
    grid-template-columns: 1fr;
    padding: 40px;
    max-width: 1000px;
    background-color: #081433;
    border-top: 6px solid #48bcd0;
    margin-bottom: 6rem;
    width: 100%;
}

#card h1 {
    color: #41C2D2;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    /* font-style: italic; */
}

#card p {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    /* font-style: italic; */
}

#card a {
    color: #41C2D2;
}

#card p.last {
    margin-bottom: 0rem;
}

#card .table {
    text-align: center;
}

#card .table img {
    margin-top: 4rem;
    /* width: 100%; */
}



@media only screen and (max-width: 1010px) {

    #card {
        margin-bottom: 4rem;
    }

    #card .table img {
        width: 100%;
    }

  }
  
  @media only screen and (max-width: 800px) {
    #card {
        padding: 2rem;
    }

    #card h1 {
        font-size: 1.8rem;
    }

    #card p {
        font-size: 1.2rem; 
    }
  }