.elementals-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3px;
}
.elementals-container .element {
  max-width: 150px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}
.elementals-container .element:not(.selected) {
  opacity: 0.4;
}
.elementals-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
@media only screen and (max-width: 650px) {
  .elementals-container .element {
    max-width: 75px;
  }
}
.selected-el {
  color: #41c2d2;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  margin-bottom: 0 !important;
}
.selected-el-nr {
  color: #41c2d2;
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}
