#game {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* padding: 20px; */
    max-width: 1000px;
    /* background-color: #081433; */
    background-image: url('../../assets/img/game-5.png');
    background-size: cover;
    border-top: 6px solid #48bcd0;
    margin-bottom: 6rem;
}

#game h1 {
    color: #41C2D2;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    /* font-style: italic; */
}

#game p {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    /* font-style: italic; */

}

#game p.third {
    margin-bottom: 0rem;
}

#game p a {
    color: #48bcd0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

#game .left {
    padding: 40px;
}

#game .right {
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#game img {
    width: 324px;
    height: 294px;
}



@media only screen and (max-width: 1010px) {

    #game {
        grid-template-columns: 1fr;
        margin-bottom: 4rem;
    }

    #game h1 {
        font-size: 1.8rem;
    }

    #game p {
        font-size: 1.2rem; 
    }

    #game p.third {
        margin-bottom: 2rem;
    }

    #game img {
        width: 100%;
        height: 100%;
    }

    #game .left {
        padding: 0rem 2rem 0rem 2rem;

    }

    #game .right {
        /* padding-top: 20px; */
        padding: 2rem 2rem 2rem 2rem;
    }

  }
  
  @media only screen and (max-width: 650px) {

  }