@keyframes move {
  100% {
    transform: translate3d(250px, 250px, -1000px);
  }
}
@media only screen and (max-width: 650px) {
  @keyframes move {
    100% {
      transform: translate3d(150px, 250px, -1000px);
    }
  }
}
@keyframes fade {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.2);
  }
}
.animation-container {
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
}
.animation-container.fade {
  animation: fade 0.8s ease-in-out;
}
.bubble-wrap {
  margin: auto;
  width: 500px;
  height: 500px;
  transform-style: preserve-3d;
  transform-origin: center center;
  perspective: 600px;
}
.bubble {
  position: absolute;
  background: black;
  opacity: 0.7;
  border-radius: 50%;
  animation: move 3s infinite;
}
.bubble:nth-child(1) {
  height: 18px;
  width: 18px;
  animation-delay: -0.2s;
  transform: translate3d(643px, 854px, 1290px);
  background: #d9267a;
}
.bubble:nth-child(2) {
  height: 30px;
  width: 30px;
  animation-delay: -0.4s;
  transform: translate3d(78px, 593px, 1629px);
  background: #d92691;
}
.bubble:nth-child(3) {
  height: 30px;
  width: 30px;
  animation-delay: -0.6s;
  transform: translate3d(470px, 477px, 1495px);
  background: #d92641;
}
.bubble:nth-child(4) {
  height: 27px;
  width: 27px;
  animation-delay: -0.8s;
  transform: translate3d(769px, 151px, 1070px);
  background: #ca26d9;
}
.bubble:nth-child(5) {
  height: 13px;
  width: 13px;
  animation-delay: -1s;
  transform: translate3d(910px, 116px, 1607px);
  background: #26afd9;
}
.bubble:nth-child(6) {
  height: 16px;
  width: 16px;
  animation-delay: -1.2s;
  transform: translate3d(887px, 303px, 1103px);
  background: #d926ca;
}
.bubble:nth-child(7) {
  height: 29px;
  width: 29px;
  animation-delay: -1.4s;
  transform: translate3d(243px, 274px, 752px);
  background: #26afd9;
}
.bubble:nth-child(8) {
  height: 4px;
  width: 4px;
  animation-delay: -1.6s;
  transform: translate3d(190px, 642px, 1448px);
  background: #d93b26;
}
.bubble:nth-child(9) {
  height: 26px;
  width: 26px;
  animation-delay: -1.8s;
  transform: translate3d(246px, 902px, 1671px);
  background: #4d26d9;
}
.bubble:nth-child(10) {
  height: 9px;
  width: 9px;
  animation-delay: -2s;
  transform: translate3d(514px, 695px, 507px);
  background: #26d0d9;
}
.bubble:nth-child(11) {
  height: 28px;
  width: 28px;
  animation-delay: -2.2s;
  transform: translate3d(857px, 568px, 969px);
  background: #2688d9;
}
.bubble:nth-child(12) {
  height: 16px;
  width: 16px;
  animation-delay: -2.4s;
  transform: translate3d(851px, 295px, 1027px);
  background: #2650d9;
}
.bubble:nth-child(13) {
  height: 5px;
  width: 5px;
  animation-delay: -2.6s;
  transform: translate3d(433px, 977px, 1556px);
  background: #d9b526;
}
.bubble:nth-child(14) {
  height: 23px;
  width: 23px;
  animation-delay: -2.8s;
  transform: translate3d(734px, 244px, 677px);
  background: #d92644;
}
.bubble:nth-child(15) {
  height: 7px;
  width: 7px;
  animation-delay: -3s;
  transform: translate3d(661px, 986px, 625px);
  background: #26d96e;
}
.bubble:nth-child(16) {
  height: 11px;
  width: 11px;
  animation-delay: -3.2s;
  transform: translate3d(817px, 338px, 1693px);
  background: #d92682;
}
.bubble:nth-child(17) {
  height: 18px;
  width: 18px;
  animation-delay: -3.4s;
  transform: translate3d(206px, 360px, 75px);
  background: #d92641;
}
.bubble:nth-child(18) {
  height: 4px;
  width: 4px;
  animation-delay: -3.6s;
  transform: translate3d(823px, 337px, 568px);
  background: #8bd926;
}
.bubble:nth-child(19) {
  height: 13px;
  width: 13px;
  animation-delay: -3.8s;
  transform: translate3d(493px, 999px, 1252px);
  background: #d926d3;
}
.bubble:nth-child(20) {
  height: 25px;
  width: 25px;
  animation-delay: -4s;
  transform: translate3d(234px, 46px, 845px);
  background: #44d926;
}
.bubble:nth-child(21) {
  height: 12px;
  width: 12px;
  animation-delay: -4.2s;
  transform: translate3d(388px, 589px, 1632px);
  background: #80d926;
}
.bubble:nth-child(22) {
  height: 4px;
  width: 4px;
  animation-delay: -4.4s;
  transform: translate3d(602px, 20px, 309px);
  background: #2653d9;
}
.bubble:nth-child(23) {
  height: 4px;
  width: 4px;
  animation-delay: -4.6s;
  transform: translate3d(120px, 84px, 1700px);
  background: #2653d9;
}
.bubble:nth-child(24) {
  height: 12px;
  width: 12px;
  animation-delay: -4.8s;
  transform: translate3d(773px, 227px, 1159px);
  background: #d96826;
}
.bubble:nth-child(25) {
  height: 5px;
  width: 5px;
  animation-delay: -5s;
  transform: translate3d(275px, 605px, 390px);
  background: #9d26d9;
}
.bubble:nth-child(26) {
  height: 29px;
  width: 29px;
  animation-delay: -5.2s;
  transform: translate3d(161px, 725px, 1925px);
  background: #26d97a;
}
.bubble:nth-child(27) {
  height: 17px;
  width: 17px;
  animation-delay: -5.4s;
  transform: translate3d(503px, 95px, 709px);
  background: #d026d9;
}
.bubble:nth-child(28) {
  height: 9px;
  width: 9px;
  animation-delay: -5.6s;
  transform: translate3d(613px, 900px, 1558px);
  background: #d026d9;
}
.bubble:nth-child(29) {
  height: 6px;
  width: 6px;
  animation-delay: -5.8s;
  transform: translate3d(328px, 745px, 1663px);
  background: #d926ca;
}
.bubble:nth-child(30) {
  height: 10px;
  width: 10px;
  animation-delay: -6s;
  transform: translate3d(336px, 108px, 1214px);
  background: #d926c4;
}
.bubble:nth-child(31) {
  height: 14px;
  width: 14px;
  animation-delay: -6.2s;
  transform: translate3d(652px, 916px, 223px);
  background: #d99426;
}
.bubble:nth-child(32) {
  height: 30px;
  width: 30px;
  animation-delay: -6.4s;
  transform: translate3d(128px, 299px, 564px);
  background: #d9265f;
}
.bubble:nth-child(33) {
  height: 12px;
  width: 12px;
  animation-delay: -6.6s;
  transform: translate3d(899px, 583px, 1716px);
  background: #d9262c;
}
.bubble:nth-child(34) {
  height: 30px;
  width: 30px;
  animation-delay: -6.8s;
  transform: translate3d(734px, 374px, 383px);
  background: #d99a26;
}
.bubble:nth-child(35) {
  height: 3px;
  width: 3px;
  animation-delay: -7s;
  transform: translate3d(924px, 87px, 1815px);
  background: #ac26d9;
}
.bubble:nth-child(36) {
  height: 18px;
  width: 18px;
  animation-delay: -7.2s;
  transform: translate3d(133px, 548px, 863px);
  background: #8026d9;
}
.bubble:nth-child(37) {
  height: 9px;
  width: 9px;
  animation-delay: -7.4s;
  transform: translate3d(63px, 237px, 1122px);
  background: #9426d9;
}
.bubble:nth-child(38) {
  height: 13px;
  width: 13px;
  animation-delay: -7.6s;
  transform: translate3d(431px, 424px, 276px);
  background: #26d94d;
}
.bubble:nth-child(39) {
  height: 9px;
  width: 9px;
  animation-delay: -7.8s;
  transform: translate3d(840px, 236px, 1622px);
  background: #26d97d;
}
.bubble:nth-child(40) {
  height: 13px;
  width: 13px;
  animation-delay: -8s;
  transform: translate3d(374px, 146px, 1474px);
  background: #5326d9;
}
.bubble:nth-child(41) {
  height: 23px;
  width: 23px;
  animation-delay: -8.2s;
  transform: translate3d(951px, 377px, 510px);
  background: #26d9d9;
}
.bubble:nth-child(42) {
  height: 4px;
  width: 4px;
  animation-delay: -8.4s;
  transform: translate3d(19px, 223px, 1075px);
  background: #a6d926;
}
.bubble:nth-child(43) {
  height: 11px;
  width: 11px;
  animation-delay: -8.6s;
  transform: translate3d(884px, 982px, 1133px);
  background: #d94426;
}
.bubble:nth-child(44) {
  height: 22px;
  width: 22px;
  animation-delay: -8.8s;
  transform: translate3d(779px, 222px, 1029px);
  background: #268ed9;
}
.bubble:nth-child(45) {
  height: 24px;
  width: 24px;
  animation-delay: -9s;
  transform: translate3d(287px, 25px, 910px);
  background: #26a3d9;
}
.bubble:nth-child(46) {
  height: 18px;
  width: 18px;
  animation-delay: -9.2s;
  transform: translate3d(607px, 886px, 88px);
  background: #d9b526;
}
.bubble:nth-child(47) {
  height: 7px;
  width: 7px;
  animation-delay: -9.4s;
  transform: translate3d(868px, 488px, 1074px);
  background: #d99426;
}
.bubble:nth-child(48) {
  height: 26px;
  width: 26px;
  animation-delay: -9.6s;
  transform: translate3d(2px, 502px, 1140px);
  background: #d94a26;
}
.bubble:nth-child(49) {
  height: 10px;
  width: 10px;
  animation-delay: -9.8s;
  transform: translate3d(304px, 940px, 484px);
  background: #d9269d;
}
.bubble:nth-child(50) {
  height: 5px;
  width: 5px;
  animation-delay: -10s;
  transform: translate3d(989px, 403px, 527px);
  background: #5c26d9;
}
.bubble:nth-child(51) {
  height: 22px;
  width: 22px;
  animation-delay: -10.2s;
  transform: translate3d(137px, 873px, 799px);
  background: #8526d9;
}
.bubble:nth-child(52) {
  height: 6px;
  width: 6px;
  animation-delay: -10.4s;
  transform: translate3d(127px, 281px, 786px);
  background: #26d929;
}
.bubble:nth-child(53) {
  height: 27px;
  width: 27px;
  animation-delay: -10.6s;
  transform: translate3d(938px, 899px, 1185px);
  background: #ac26d9;
}
.bubble:nth-child(54) {
  height: 2px;
  width: 2px;
  animation-delay: -10.8s;
  transform: translate3d(424px, 256px, 738px);
  background: #bbd926;
}
.bubble:nth-child(55) {
  height: 7px;
  width: 7px;
  animation-delay: -11s;
  transform: translate3d(915px, 229px, 310px);
  background: #d96226;
}
.bubble:nth-child(56) {
  height: 21px;
  width: 21px;
  animation-delay: -11.2s;
  transform: translate3d(33px, 305px, 413px);
  background: #cad926;
}
.bubble:nth-child(57) {
  height: 30px;
  width: 30px;
  animation-delay: -11.4s;
  transform: translate3d(766px, 108px, 1673px);
  background: #d92668;
}
.bubble:nth-child(58) {
  height: 19px;
  width: 19px;
  animation-delay: -11.6s;
  transform: translate3d(164px, 978px, 1868px);
  background: #5fd926;
}
.bubble:nth-child(59) {
  height: 27px;
  width: 27px;
  animation-delay: -11.8s;
  transform: translate3d(9px, 725px, 1528px);
  background: #26d994;
}
.bubble:nth-child(60) {
  height: 13px;
  width: 13px;
  animation-delay: -12s;
  transform: translate3d(413px, 670px, 850px);
  background: #26d93e;
}
.bubble:nth-child(61) {
  height: 27px;
  width: 27px;
  animation-delay: -12.2s;
  transform: translate3d(926px, 425px, 276px);
  background: #d9a026;
}
.bubble:nth-child(62) {
  height: 20px;
  width: 20px;
  animation-delay: -12.4s;
  transform: translate3d(259px, 811px, 1157px);
  background: #26afd9;
}
.bubble:nth-child(63) {
  height: 17px;
  width: 17px;
  animation-delay: -12.6s;
  transform: translate3d(350px, 282px, 780px);
  background: #8026d9;
}
.bubble:nth-child(64) {
  height: 15px;
  width: 15px;
  animation-delay: -12.8s;
  transform: translate3d(123px, 922px, 148px);
  background: #d9a626;
}
.bubble:nth-child(65) {
  height: 22px;
  width: 22px;
  animation-delay: -13s;
  transform: translate3d(528px, 309px, 772px);
  background: #d92650;
}
.bubble:nth-child(66) {
  height: 2px;
  width: 2px;
  animation-delay: -13.2s;
  transform: translate3d(771px, 295px, 191px);
  background: #b8d926;
}
.bubble:nth-child(67) {
  height: 18px;
  width: 18px;
  animation-delay: -13.4s;
  transform: translate3d(370px, 489px, 1455px);
  background: #5c26d9;
}
.bubble:nth-child(68) {
  height: 8px;
  width: 8px;
  animation-delay: -13.6s;
  transform: translate3d(794px, 316px, 1576px);
  background: #5626d9;
}
.bubble:nth-child(69) {
  height: 17px;
  width: 17px;
  animation-delay: -13.8s;
  transform: translate3d(85px, 346px, 1511px);
  background: #d92f26;
}
.bubble:nth-child(70) {
  height: 10px;
  width: 10px;
  animation-delay: -14s;
  transform: translate3d(137px, 392px, 1684px);
  background: #2688d9;
}
.bubble:nth-child(71) {
  height: 6px;
  width: 6px;
  animation-delay: -14.2s;
  transform: translate3d(398px, 444px, 1717px);
  background: #d9267d;
}
.bubble:nth-child(72) {
  height: 20px;
  width: 20px;
  animation-delay: -14.4s;
  transform: translate3d(909px, 462px, 748px);
  background: #d9262f;
}
.bubble:nth-child(73) {
  height: 21px;
  width: 21px;
  animation-delay: -14.6s;
  transform: translate3d(787px, 517px, 922px);
  background: #d9268e;
}
.bubble:nth-child(74) {
  height: 2px;
  width: 2px;
  animation-delay: -14.8s;
  transform: translate3d(731px, 848px, 21px);
  background: #26d6d9;
}
.bubble:nth-child(75) {
  height: 23px;
  width: 23px;
  animation-delay: -15s;
  transform: translate3d(551px, 188px, 377px);
  background: #5f26d9;
}
.bubble:nth-child(76) {
  height: 4px;
  width: 4px;
  animation-delay: -15.2s;
  transform: translate3d(503px, 48px, 1544px);
  background: #d926c1;
}
.bubble:nth-child(77) {
  height: 25px;
  width: 25px;
  animation-delay: -15.4s;
  transform: translate3d(620px, 370px, 231px);
  background: #ac26d9;
}
.bubble:nth-child(78) {
  height: 1px;
  width: 1px;
  animation-delay: -15.6s;
  transform: translate3d(927px, 449px, 30px);
  background: #26d99a;
}
.bubble:nth-child(79) {
  height: 5px;
  width: 5px;
  animation-delay: -15.8s;
  transform: translate3d(782px, 561px, 1441px);
  background: #d92635;
}
.bubble:nth-child(80) {
  height: 9px;
  width: 9px;
  animation-delay: -16s;
  transform: translate3d(666px, 143px, 156px);
  background: #d9b826;
}
.bubble:nth-child(81) {
  height: 27px;
  width: 27px;
  animation-delay: -16.2s;
  transform: translate3d(704px, 270px, 1330px);
  background: #d9d026;
}
.bubble:nth-child(82) {
  height: 18px;
  width: 18px;
  animation-delay: -16.4s;
  transform: translate3d(959px, 899px, 621px);
  background: #d92f26;
}
.bubble:nth-child(83) {
  height: 25px;
  width: 25px;
  animation-delay: -16.6s;
  transform: translate3d(957px, 718px, 1977px);
  background: #a626d9;
}
.bubble:nth-child(84) {
  height: 8px;
  width: 8px;
  animation-delay: -16.8s;
  transform: translate3d(98px, 382px, 1580px);
  background: #26d97d;
}
.bubble:nth-child(85) {
  height: 27px;
  width: 27px;
  animation-delay: -17s;
  transform: translate3d(874px, 288px, 1907px);
  background: #26d9af;
}
.bubble:nth-child(86) {
  height: 4px;
  width: 4px;
  animation-delay: -17.2s;
  transform: translate3d(395px, 381px, 719px);
  background: #d98226;
}
.bubble:nth-child(87) {
  height: 8px;
  width: 8px;
  animation-delay: -17.4s;
  transform: translate3d(258px, 602px, 980px);
  background: #d9af26;
}
.bubble:nth-child(88) {
  height: 9px;
  width: 9px;
  animation-delay: -17.6s;
  transform: translate3d(650px, 494px, 636px);
  background: #cdd926;
}
.bubble:nth-child(89) {
  height: 7px;
  width: 7px;
  animation-delay: -17.8s;
  transform: translate3d(81px, 963px, 1775px);
  background: #4dd926;
}
.bubble:nth-child(90) {
  height: 9px;
  width: 9px;
  animation-delay: -18s;
  transform: translate3d(880px, 422px, 1591px);
  background: #26d92c;
}
.bubble:nth-child(91) {
  height: 29px;
  width: 29px;
  animation-delay: -18.2s;
  transform: translate3d(831px, 928px, 422px);
  background: #d92682;
}
.bubble:nth-child(92) {
  height: 26px;
  width: 26px;
  animation-delay: -18.4s;
  transform: translate3d(102px, 830px, 545px);
  background: #32d926;
}
.bubble:nth-child(93) {
  height: 16px;
  width: 16px;
  animation-delay: -18.6s;
  transform: translate3d(208px, 686px, 523px);
  background: #26d9b2;
}
.bubble:nth-child(94) {
  height: 5px;
  width: 5px;
  animation-delay: -18.8s;
  transform: translate3d(6px, 737px, 670px);
  background: #26d9d3;
}
.bubble:nth-child(95) {
  height: 8px;
  width: 8px;
  animation-delay: -19s;
  transform: translate3d(545px, 440px, 1155px);
  background: #d026d9;
}
.bubble:nth-child(96) {
  height: 12px;
  width: 12px;
  animation-delay: -19.2s;
  transform: translate3d(441px, 69px, 1062px);
  background: #26d99d;
}
.bubble:nth-child(97) {
  height: 4px;
  width: 4px;
  animation-delay: -19.4s;
  transform: translate3d(133px, 281px, 1839px);
  background: #26d6d9;
}
.bubble:nth-child(98) {
  height: 30px;
  width: 30px;
  animation-delay: -19.6s;
  transform: translate3d(925px, 53px, 1540px);
  background: #d97a26;
}
.bubble:nth-child(99) {
  height: 15px;
  width: 15px;
  animation-delay: -19.8s;
  transform: translate3d(811px, 817px, 1612px);
  background: #d92c26;
}
.bubble:nth-child(100) {
  height: 21px;
  width: 21px;
  animation-delay: -20s;
  transform: translate3d(912px, 806px, 1985px);
  background: #d9bb26;
}
