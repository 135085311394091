#community {
    width: 1000px;
    background-color: #41C2D2;
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* border-top: 6px solid #48bcd0; */
    margin-bottom: 6rem;
    text-align: center;
    /* vertical-align: middle; */
}

#community h1 {
    color: #081433;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    /* font-style: italic; */
}

#community a {
    text-decoration: none;
}

/* #community .one {
    vertical-align: middle;
    display: inline-block;
}

#community .one h1 {
    vertical-align: middle;
} */

#community button {
    display: inline-block;
    /* min-width: 200px; */
    padding: 14px;
    color: #fff;
    font-size: 12px;
    border: none;
    background-color: #081433;
}

#community button img {
    width: 20px;
    vertical-align: middle;
    margin: 0px 10px 0px 0px;
}

#community button .btn-text {
    vertical-align: middle;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    /* font-style: italic; */
}

#community button:hover {
    background-color: #102866;
}



@media only screen and (max-width: 1010px) {

    #community {
        width: 100%;
        grid-template-columns: 1fr;
        margin-bottom: 4rem;
    }

    #community h1 {
        margin-bottom: 2rem;
    }

    #community button {
        min-width: 200px;
    }

    #community .two button {
        margin-bottom: 2rem; 
    }

    #community .three button {
        margin-bottom: 2rem; 
    }

  }
  
  @media only screen and (max-width: 650px) {

  }