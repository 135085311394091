#gallery {
    margin-bottom: 6rem;
    width: 1200px;
    /* background-color: red; */
}

#gallery img {
    /* max-width: 340px; */
    width: 32rem;
}
/* 
.carouselone {
    width: 1000px;
    background-color: red;
} */

.card {
    width: 300px;
    height: 300px;
    background: #333;
    color: #fff;
    font-size: 30px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.jMNKZk button {
    background-color: rgba(103,58,183,1);
    color: #fff;
}

.sc-iCfMLu button {
    background-color: rgba(103,58,183,1);
    color: #fff;
}

.fVgyLU {
    background-color: rgba(103,58,183,1)!important;
    color: #fff!important;
    transition: none!important;
}

.fViXLK {
    background-color: rgba(103,58,183,1)!important;
    color: #fff!important;
    transition: none!important;
}

/* 
.sc-bdvvtL {
    background-color: rgba(103,58,183,1);
    color: #fff;
} */



.sc-iCfMLu button.lcUbOd {
    box-shadow: none!important;
    background-color: rgb(255, 255, 255)!important;
}

.bBjxWM {
    box-shadow: none!important;
    background-color: rgb(255, 255, 255)!important;
}

button .bBjxWM {
    box-shadow: none!important;
    background-color: rgb(255, 255, 255)!important;
}


@media only screen and (max-width: 1200px) {

    #gallery {
        width: 100%;
    }

    #gallery img {
        /* max-width: 320px; */
        max-width: 30rem;
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }

  }



@media only screen and (max-width: 1010px) {

    #gallery {
        margin-bottom: 4rem;
    }

  }

  @media only screen and (max-width: 927px) {

    #gallery img {
        max-width: none;
        width: 100%;
    }

    .sc-bdvvtL {
        display: none!important;
    }

  }
  
  @media only screen and (max-width: 650px) {
    #gallery img {
        padding-left: none;
        padding-right: none;
    }
  }