#hero {
    margin-bottom: 6rem;
}

#hero img {
    max-width: 1000px;
    width: 100%;
}

#hero img.desktop {
    display: block;
}

#hero img.mobile {
    display: none;
}

@media only screen and (max-width: 1010px) {

    #hero {
        margin-bottom: 2rem;
    }

  }
  
  @media only screen and (max-width: 800px) {

    #hero {
        width: 100%;
    }

    #hero img.desktop {
        display: none;
    }
    
    #hero img.mobile {
        display: block;
    }
  }