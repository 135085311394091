#footer {
  display: grid;
  min-height: 100px;
  grid-auto-flow: column;
  background-color: none;
  grid-gap: 1.5em;
  padding: 0 0px;
  min-width: 1000px;
  /* background-color: red; */
  /* margin-bottom: 4rem; */
}

#logo {
  display: grid;
  justify-content: start;
  align-content: center;
}

.logo img {
  width: 25rem;
}

#links {
  display: grid;
  justify-content: right;
  align-content: center;
  grid-auto-flow: column;
  grid-gap: 1.5em;
  font-family: "Open Sans", sans-serif;
}

#links button {
  min-width: 50px;
  min-height: 50px;
}

#links button.social {
  background: none;
  padding: 8px 15px;
  /* border-image-source: linear-gradient(to left, #354E8F, #48BCD0); */
  /* opacity: .3;
    transition: 3s; */
}

#links button.social:hover {
  /* background: linear-gradient(90.83deg, #48BCD0 0.36%, #354E8F 99.77%); */
  /* opacity: 1; */
  background-color: #48bcd0 !important;
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
}

#links button.claim-nft {
  border: none !important;
  background: #48bcd0;
  color: #fff;
  padding: 8px 40px;
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

#links img {
  vertical-align: middle;
}

a:hover {
  color: #0474bc;
}

@media only screen and (max-width: 1010px) {
  #footer {
    min-width: 0px;
    width: 100%;
    grid-gap: 0rem;
  }

  #links a {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  #footer {
    min-width: 0px;
    width: 100%;
  }

  .logo img {
    width: 20rem;
  }

  #links button.social {
    display: none;
  }
}
