#pop-up-warning {
  position: fixed;
  bottom: 2rem;
  right: -100%;
  padding: 2rem;
  transition: right 0.5s ease-in-out;
  background: #040a1a;
  border: 2px solid #48bcd0;
  color: #41c2d2;
  font-size: 1.4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  box-shadow: 0 0 7px #fff;
}
#pop-up-warning.visible {
  right: -5px;
}
#pop-up-warning.animation-out {
  right: -100%;
}
