.Connect-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4rem;
}

.App-connect {
  font-size: 2.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.App-button-one {
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  /* border-radius: 20px; */
  height: 5rem;
  width: auto;
  color: #fff;
  border: none;
  font-size: 1.4rem;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.App-button-one:hover {
  /* background: linear-gradient(90.83deg, #354e8f 99.77%, #48bcd0 0.36%); */
  background: #48bcd0;
}
