#faq {
  /* display: grid;
    grid-template-columns: 1fr; */
  padding: 40px;
  max-width: 1000px;
  width: 100%;
  background-color: #081433;
  border-top: 6px solid #48bcd0;
  margin-bottom: 6rem;
}

#faq h1 {
  color: #41c2d2;
  font-size: 2rem;
  margin-bottom: 4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-style: italic;
}

#faq p {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  /* font-style: italic; */
}

#faq p a {
  color: #41c2d2;
}

.accordion {
  border-radius: 0px !important;
  border: 0px !important;
}

.accordion-item {
  border: none;
  border-top: 3px solid #48bcd0 !important;
  background-color: inherit !important;
}

.accordion-item button {
  color: #fff !important;
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  /* font-style: italic; */
}

.accordion .accordion-item .title:after {
  color: #fff !important;
  font-size: 2rem !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
  content: "\002b" !important;
}

.accordion .accordion-item.active .title:after {
  color: #fff !important;
  font-size: 2rem !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
  content: "\2212" !important;
}

/* button.title::after {
    color: #fff!important;
    text-shadow: 0 0 0 #48bcd0;
} */

.accordion .accordion-item .title {
  background-color: #081433 !important;
  padding: 18px 0px !important;
}

/* .accordion .accordion-item .title p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
} */

.accordion .accordion-item:first-child .title {
  border-radius: 0px !important;
}

.accordion .accordion-item:last-child .title {
  border-radius: 0px !important;
}

@media only screen and (max-width: 1010px) {
  #faq {
    margin-bottom: 2rem;
    padding: 2rem;
  }
}

@media only screen and (max-width: 800px) {
  .accordion-item button {
    font-size: 1.2rem;
  }

  #faq p {
    font-size: 1.2rem;
  }
}
