.box-container {
  border: solid 3px #48bcd0;
  width: 100%;
  max-width: 1000px;
  padding: 5rem 4rem;
  margin: 0 auto;
  color: #fff;
  position: relative;
  background: #091532;
  margin-bottom: 5rem;
}
.box-container .inner {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.nr-circle {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #48bcd0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
}
.carouselone img {
  max-width: 400px;
  width: 100%;
}
.box-container .title {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 2rem auto;
}
.box-container .box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
}
.box-container .elements-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  /* padding: 0rem; */
}
.box-container .elements-container img:hover {
  cursor: pointer;
  opacity: 0.9;
}
.elements-container img:not(.selected) {
  opacity: 0.4;
  transition: all 0.3s ease-in-out;
}
.loading {
  margin: auto;
  font-size: 2rem;
}
.nft-carousel .rec-carousel {
  padding: 0 15rem;
}
@media only screen and (max-width: 650px) {
  .nft-carousel .rec-carousel {
    padding: 0;
  }
}
.rec-arrow:disabled {
  opacity: 0.3;
}
.rec-arrow:hover {
  color: rgb(70, 177, 201) !important;
}
.get-alex-container {
  display: flex;
  justify-content: center;
  gap: 35px;
}
.experiment-btns-container {
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 20px;
}
.experiment-btns-container .btn-default {
  margin: 0;
}
.experiment-btns-container .btn-default:first-of-type {
  margin-left: auto;
}
.experiment-btns-container .btn-default:last-of-type {
  margin-right: auto;
}
