.launcher-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4rem;
  padding-top: 2rem;
}

.launcherButton {
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  height: 5rem;
  width: auto;
  color: #fff;
  border: none;
  font-size: 1.4rem;
  padding-left: 30px;
  padding-right: 30px;
   font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.launcherButton:hover {
  /* background: linear-gradient(90.83deg, #354e8f 99.77%, #48bcd0 0.36%); */
  background: #48bcd0;
}
