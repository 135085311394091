/* .dropdown {
    position: relative;
} */

.wallet-button {
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  border-radius: 0px;
  background-size: 150% auto;
  height: 5rem;
  width: auto;
  color: #fff;
  border: none;
  font-size: 1.4rem;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}
.wallet-button:hover {
  background-position: 80% center;
}

.dropdown-menu {
  min-width: 19rem;
  margin-top: 1rem;
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  border: none;
  padding: 0.4rem;
}

.dropdown-item {
  background-color: #081433;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #fff;
  position: relative;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}

.dropdown-item:hover {
  background-color: #1d2b4f;
  color: #fff;
}

.dropdown-item .name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.dropdown-item img {
  width: 3rem;
}

.btn-secondary:focus {
  box-shadow: none !important;
}
