.alkimi-card.video {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* padding: 20px; */
}

.alkimi-card.video h1 {
  color: #41c2d2;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  /* font-style: italic; */
}

.alkimi-card.video p {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  /* font-style: italic; */
}

.alkimi-card.video .right {
  padding-left: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.alkimi-card.video img {
  width: 324px;
  height: 294px;
}

@media only screen and (max-width: 1010px) {
  .alkimi-card.video {
    grid-template-columns: 1fr;
    margin-bottom: 4rem;
  }

  .alkimi-card.video h1 {
    font-size: 1.8rem;
  }

  .alkimi-card.video p {
    font-size: 1.2rem;
  }

  .alkimi-card.video img {
    width: 100%;
    height: 100%;
  }

  .alkimi-card.video .left {
    padding: 2rem 2rem 0rem 2rem;
  }

  .alkimi-card.video .right {
    /* padding-top: 20px; */
    padding: 0rem 2rem 2rem 2rem;
  }
}

@media only screen and (max-width: 650px) {
}
