.alkimi-card {
  padding: 40px;
  max-width: 1000px;
  background-color: #081433;
  border-top: 6px solid #48bcd0;
  margin-bottom: 6rem;
  width: 100%;
  color: #fff;
}

.alkimi-card h2 {
  color: #41c2d2;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  /* font-style: italic; */
}

.alkimi-card p {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  /* font-style: italic; */
}

.txt-center {
  text-align: center;
}

@media only screen and (max-width: 1010px) {
  .alkimi-card h2 {
    font-size: 1.8rem;
  }

  .alkimi-card p {
    font-size: 1.2rem;
  }

  .alkimi-card {
    /* padding-top: 20px; */
    padding: 2rem;
  }
}
