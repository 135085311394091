.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.arrows {
  width: 5rem;
  height: 5rem;
}

.leftArrow {
  left: 1rem;
  /* margin-right: 2rem; */
}

.rightArrow {
  right: 1rem;
}

.arrow-box {
  text-align: center;
  margin-top: 2rem;
}

.arrow-box .leftArrow {
  margin-right: 1rem;
}

.arrow-box .rightArrow {
  margin-left: 1rem;
}


/* #links button.social {
  padding: 8px 15px;
  border-image-source: linear-gradient(to left, #354E8F, #48BCD0);
}

#links button.social:hover {
  background: linear-gradient(90.83deg, #48BCD0 0.36%, #354E8F 99.77%);
} */

.profile-image {
  height: 10rem;
  width: 10rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.user-profile p {
  font-size: 1.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}

.profile p {
  margin-bottom: 0rem;
}

.nft-container {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 30px;
  /* background-color: #e3e3e3; */
  background-color: #081433;
  border: 5px solid #48bcd0;
  /* border-bottom: 5px solid #41C2D2; */
  max-width: 1000px;
  width: 1000px;
  padding: 40px;
  margin-bottom: 4rem;
  /* margin: 0px 20px 60px 20px; */
}

.left-column img.image {
  width: 100%;
}

.tile {
  /* background-color: #fff; */
  background-color: #081333;
  margin-bottom: 25px;
  padding: 0px 30px;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
}

.tile.props {
  margin-bottom: 0px;
}

.tile h1 {
  font-size: 1.5rem;
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #48bcd0;
}

.tile.details h1{
  margin-bottom: 15px;
}

.tile p {
  font-size: 1.2rem;
  /* color: #707070; */
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

/* .tile.description p {
} */

.tile.details p {
  padding: 5px 0px;
}

.tile .properties p {
  padding: 5px 0px;
}

.information {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.tile .properties {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  text-align: center;
}

.property {
  /* background-color: #eef9fd; */
  background-color: #1e2a4b;
  border: 2px solid #73ceed;
  min-height: 80px;
  /* border-radius: 10px; */
  padding: 5px;
}

.property p.trait {
  text-transform: uppercase;
  color: #73ceed;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1rem;
}

.property p.type {
  color: #fff;
  font-size: 1.1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.property p.rarity {
  font-weight: bold;
}


@media only screen and (max-width: 1010px) {

  .profile {
      width: 100%;
  }

}

@media only screen and (max-width: 900px) {
  .nft-container {
    grid-template-columns: 1fr;
    /* width: 100%!important; */
    padding: 2rem;
  }


  .tile {
    padding: 0px 0px;
  }

  .tile.details .mobile-hide {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .tile .properties {
    grid-template-columns: 1fr 1fr;
  }

  .property {
    min-height: 0px;
  }
}
