#navbar {
  display: grid;
  min-height: 100px;
  grid-auto-flow: column;
  background-color: none;
  grid-gap: 1.5em;
  padding: 0 0px;
  width: 100%;
  /* min-width: 1000px; */
  /* background-color: red; */
  /* margin-bottom: 4rem; */
}
nav {
  width: 100%;
  max-width: 1000px;
}

#logo {
  display: grid;
  justify-content: start;
  align-content: center;
}

.logo img {
  width: 25rem;
}

.logo.mobile {
  display: none;
}

.logo.desktop {
  display: block;
}

/* .opensea {
  height: 30px;
  width: 32px;
} */

#links {
  display: grid;
  justify-content: right;
  align-content: center;
  grid-auto-flow: column;
  grid-gap: 1.5em;
  font-family: "Open Sans", sans-serif;
}
/* 
#links a {

} */

#links button {
  min-width: 50px;
  min-height: 52px;
}

#links button.social {
  background-color: transparent;
  padding: 8px 15px;
  /* border-image-source: linear-gradient(to left, #354E8F, #48BCD0); */
  border: solid 3px #48bcd0;
  transition: all 0.3s ease-in-out;
  position: relative;
  backdrop-filter: blur(5px);
}

#links button.social:hover {
  /* background: linear-gradient(90.83deg, #48BCD0 0.36%, #354E8F 99.77%); */
  background-color: #48bcd0 !important;
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
}

#links button.claim-nft {
  border: none !important;
  background: #48bcd0;
  color: #fff;
  padding: 8px 40px;
  background: linear-gradient(90.83deg, #48bcd0 0.36%, #354e8f 99.77%);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

#links img {
  vertical-align: middle;
}

a:hover {
  color: #0474bc;
}

@media only screen and (max-width: 1020px) {
  #navbar {
    /* min-width: 100vw; */
    width: 100%;
    /* padding-left: 2rem;
    padding-right: 2rem; */
    /* align-items: inherit!important; */
    /* grid-template-columns: 1fr; */
  }

  #links a {
    display: none;
  }

  #logo {
    text-align: left;
  }

  .logo.desktop {
    display: none;
  }

  .logo.mobile {
    display: block;
  }

  #navbar .logo img {
    width: 5rem;
  }
}

@media only screen and (max-width: 650px) {
  #navbar {
  }

  #footer .logo img {
    width: 20rem;
  }

  #links button.social {
    display: none;
  }

  #links button {
    /* width: auto; */
    min-height: 25px;
  }

  #links button .name {
    min-height: 50px;
  }

  .App-button-one {
    font-size: 1rem !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .App-button-two {
    font-size: 1rem !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .App-button-three {
    font-size: 1rem !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (max-width: 600px) {
  /* #footer .logo img {
    width: 15rem;
} */
}
