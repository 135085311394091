#overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 2;
  backdrop-filter: blur(10px);
  background-color: rgba(9, 21, 50, 0.9);
}
#overlay .close {
  position: absolute;
  top: 1rem;
  right: 2rem;
  width: 2rem;
  cursor: pointer;
  background: none;
  border: none;
  color: #fff;
  font-size: 3rem;
}
#overlay h3 {
  margin-bottom: 2rem;
}
#overlay .img-container {
  width: 100%;
  max-width: 1000px;
  max-height: 70%;
  margin: 0 auto;
  padding: 0 10rem;
  color: #fff;
  position: relative;
  background: #091532;
}
#overlay .img-container img {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.new-alex h2 {
  color: #41c2d2;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}
.jump-in {
  position: relative;
  animation: jump-in 1s cubic-bezier(0.3, 2.2, 0.5, 1);
  width: 100%;
  max-width: 600px;
  height: auto;
  padding: 0 2rem;
}

@keyframes jump-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
